import React, {useEffect, useState} from 'react';
import {Container} from '@mui/material';
import PageHeader from '../../containers/PageHeader';
import {useParams} from 'react-router-dom';
import {
  collection,
  getDocs,
  limit,
  query,
  where,
  orderBy,
} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import DoodleGrid from '../../containers/DoodleGrid';
import {Helmet} from 'react-helmet';
import {APP_LOGO} from '../../constants';

const DoodleTagScreen = () => {
  const {tag} = useParams();
  const [doodles, setDoodles] = useState([]);

  useEffect(() => {
    getDocs(
      query(
        collection(db, 'doodles'),
        where('tags', 'array-contains', tag),
        where('deleted', '==', false),
        where('public', '==', true),
        orderBy('likes', 'desc'),
        limit(20),
      ),
    )
      .then(snap => {
        setDoodles(snap.docs.map(doc => ({id: doc.id, ...doc.data()})));
      })
      .catch(err => {
        console.log(err);
      });
  }, [tag]);

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>#{tag}</title>
        <meta
          property="og:image"
          content={doodles.length > 0 ? doodles[0].uri : APP_LOGO}
        />
      </Helmet>
      <PageHeader>#{tag}</PageHeader>
      <DoodleGrid doodles={doodles} />
    </Container>
  );
};

export default DoodleTagScreen;
