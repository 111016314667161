import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  Grid,
} from '@mui/material';
import {db} from '../../clients/Firebase';
import {collection, doc, getDoc, getDocs} from 'firebase/firestore';
import PageHeader from '../../containers/PageHeader';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../../containers/App/selectors';
import {connect} from 'react-redux';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {setIsAuthenticating} from '../../containers/App/actions';
import AddressForm from '../../containers/AddressForm';
import FunctionClient from '../../clients/Functions';
import {LoadingButton} from '@mui/lab';
import mixpanel from 'mixpanel-browser';
import {useNavigate, useParams} from 'react-router-dom';
import {getProductImageById, getScale} from '../../utils';
import DoodleProductDescription from '../../containers/DoodleProductDescription';
import {Helmet} from 'react-helmet';

const ProductScreen = ({user, dispatch}) => {
  const {productId, doodleId} = useParams();

  const [product, setProduct] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [address, setAddress] = useState(false);
  const [shippingMethod, setShippingMethod] = useState(null);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [loadingMethods, setLoadingMethods] = useState(false);
  const [shipping, setShipping] = useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [cardEntryComplete, setCardEntryComplete] = useState(false);
  const [creatingOrder, setCreatingOrder] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  useEffect(() => {
    loadProduct();
  }, [productId]);

  useEffect(() => {
    if (selectedOption) {
      setThumbnail(
        options.filter(o => o.id === selectedOption).pop().thumbnail,
      );
    }
  }, [selectedOption]);

  useEffect(() => {
    if (product) {
      window.prerenderReady = true;
    }
  }, [product]);

  useEffect(() => {
    if (options.length > 0) {
      setSelectedOption(options[0].id);
    }
  }, [options]);

  useEffect(() => {
    if (address) {
      updateShippingAsync();
    }
  }, [address, selectedOption]);

  useEffect(() => {
    if (shippingMethod) {
      setShipping(parseFloat(shippingMethod.rate));
      updateTaxesAsync();
    }
  }, [shippingMethod]);

  useEffect(() => {
    if (product) setTotal(product.price + shipping + tax);
  }, [tax, shipping, product]);

  const loadProduct = async () => {
    const [productDoc, optionsDocs, doodleDoc] = await Promise.all([
      getDoc(doc(db, `doodles/${doodleId}/products`, productId)),
      getDocs(
        collection(db, `doodles/${doodleId}/products/${productId}/options`),
      ),
      getDoc(doc(db, `doodles`, doodleId)),
    ]);

    setOptions(optionsDocs.docs.map(o => o.data()));

    setProduct(productDoc.data());
  };

  const updateShippingAsync = async () => {
    try {
      setLoadingMethods(true);

      const res = await FunctionClient.fetchShippingAsync({
        recipient: {
          country_code: address.country,
          state_code: address.state,
          city: address.city,
          zip: address.zip,
        },
        items: [{variant_id: options[0].variant_id, quantity: 1}],
      });

      setLoadingMethods(false);

      setShippingMethod(res[0]);

      setShippingMethods(res);
    } catch (err) {
      setLoadingMethods(false);
      alert(err.message);
    }
  };

  const updateTaxesAsync = async () => {
    try {
      const {required, rate, shipping_taxable} =
        await FunctionClient.fetchTaxRatesAsync({
          recipient: {
            city: address.city,
            state_code: address.state,
            country_code: address.country,
            zip: address.zip,
          },
        });

      let sub = product.price;

      if (shipping_taxable) sub += parseFloat(shippingMethod.rate);

      if (required) setTax(sub * rate);
    } catch (err) {
      setLoadingMethods(false);
      alert(err.message);
    }
  };

  const handlePurchasePrint = async () => {
    try {
      if (!user) {
        dispatch(setIsAuthenticating(true));
        return;
      }

      mixpanel.track('Attempting Print Purchase', {
        product: product.title,
      });

      setCreatingOrder(true);

      const {token} = await stripe.createToken(
        elements.getElement(CardElement),
      );

      const payload = {
        shipping: shippingMethod.code,
        recipient: {
          name: user.displayName,
          address1: address.address,
          city: address.city,
          state_code: address.state,
          country_code: address.country,
          zip: address.zip,
          email: user.email,
        },
        items: [
          {
            quantity: 1,
            sync_variant_id: selectedOption,
            retail_price: product.price,
          },
        ],
        retail_costs: {
          currency: 'usd',
          shipping,
          tax,
          subtotal: product.price,
          total,
        },
      };

      switch (product.type) {
        case 'shirt':
          payload.items[0].files = [
            {
              type: 'front',
              url: `https://firebasestorage.googleapis.com/v0/b/getadoodle-69.appspot.com/o/shirts%2F${doodleId}.png?alt=media`,
              position: {
                area_width: 1800,
                area_height: 2400,
                width: 800,
                height: 1012.65,
                left: (1800 * 0.5) / 2,
                top: (2400 * 0.5) / 3,
              },
            },
          ];
          break;
        case 'sticker':
          payload.items[0].files = [
            {
              type: 'default',
              url: `https://firebasestorage.googleapis.com/v0/b/getadoodle-69.appspot.com/o/doodles%2F${doodleId}.png?alt=media`,
              position: {
                area_width: 5400,
                area_height: 5400,
                width: 5400,
                height: 5400,
                left: 0,
                top: 0,
              },
            },
          ];
          break;
        default:
          payload.items[0].files = [
            {
              type: 'default',
              url: `https://firebasestorage.googleapis.com/v0/b/getadoodle-69.appspot.com/o/doodles%2F${doodleId}.png?alt=media`,
              position: {
                area_width: 5400,
                area_height: 5400,
                width: 5400 * 0.5,
                height: 5400 * 0.5,
                left: (5400 * 0.5) / 2,
                top: (5400 * 0.5) / 2,
              },
            },
          ];
      }

      const res = await FunctionClient.createPrintfulOrderAsync({
        doodleId,
        doodleProduct: product,
        payload,
        token,
      });

      navigate(`/account/orders/${res.id}`);

      mixpanel.track('Print Purchased', {
        product: product.title,
      });

      setCreatingOrder(false);
    } catch (err) {
      setCreatingOrder(false);
      alert(err.message);
    }
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  if (!product) return <LinearProgress variant="indeterminate" />;

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>{product.title}</title>
        <meta name="description" content="Getadoodle Terms of Service." />
        <meta
          property="og:image"
          content={getProductImageById(doodleId, product.type)}
        />
      </Helmet>
      <PageHeader>Checkout</PageHeader>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12} sm={12}>
          <div
            onClick={() => {
              const image = new Image();
              image.src = thumbnail;
              const w = window.open('');
              w.document.write(image.outerHTML);
            }}
            style={{
              cursor: 'pointer',
              backgroundSize: 'cover',
              backgroundColor:
                product.type === 'shirt' ? `#ffffff` : 'transparent',
              overflow: 'hidden',
              display: 'flex',
            }}>
            <img
              style={{
                transform: `scale(${getScale(product)})`,
              }}
              src={thumbnail}
            />
          </div>
          {product.thumbnailBack && (
            <Stack spacing={2} direction="row">
              <Typography
                style={{
                  cursor: 'pointer',
                  textDecoration:
                    thumbnail === product.thumbnail ? 'underline' : 'none',
                }}
                onClick={() => setThumbnail(product.thumbnail)}
                variant="caption">
                Front
              </Typography>
              <Typography
                style={{
                  cursor: 'pointer',
                  textDecoration:
                    thumbnail === product.thumbnailBack ? 'underline' : 'none',
                }}
                onClick={() => setThumbnail(product.thumbnailBack)}
                variant="caption">
                Back
              </Typography>
            </Stack>
          )}
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Typography variant="h5" bold>
            <strong>{product.title}</strong>
          </Typography>
          <Typography paragraph color="secondary" variant="h3">
            <strong>${product.price}</strong>
          </Typography>
          <DoodleProductDescription product={product} />
          <br />
          <Stack style={{width: '100%'}} spacing={2}>
            <Typography>Options</Typography>
            <Select
              onChange={e => setSelectedOption(e.target.value)}
              value={selectedOption}
              fullWidth>
              {options.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
            {!user && (
              <Button
                onClick={() => dispatch(setIsAuthenticating(true))}
                fullWidth
                variant="contained">
                LOGIN
              </Button>
            )}
            <br />
            {user && (
              <Stack spacing={2}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography paragraph>Shipping Address</Typography>
                  {Boolean(address) && (
                    <Typography
                      className="clickable"
                      onClick={() => setAddress(null)}
                      color="blue">
                      Edit
                    </Typography>
                  )}
                </Stack>
                {Boolean(address) && [
                  <Typography>{address.address}</Typography>,
                  <Typography>
                    {address.city}, {address.state}
                  </Typography>,
                ]}
                <div style={{display: address ? 'none' : 'block'}}>
                  <AddressForm
                    onComplete={_address => {
                      setAddress(_address);
                    }}
                  />
                </div>
                <br />
                {loadingMethods && <CircularProgress />}
                {shippingMethods.length > 0 && (
                  <Stack spacing={2}>
                    <Typography>Shipping Methods</Typography>
                    <FormControl>
                      {shippingMethods.map(method => (
                        <FormControlLabel
                          key={method.id}
                          control={
                            <Checkbox
                              onChange={e => {
                                if (e.target.checked) setShippingMethod(method);
                              }}
                              checked={shippingMethod.id === method.id}
                            />
                          }
                          label={
                            <Typography variant="caption">
                              ${method.rate} {method.name}
                            </Typography>
                          }
                        />
                      ))}
                    </FormControl>
                  </Stack>
                )}
                <br />
                {address && !loadingMethods && shippingMethod && (
                  <Stack spacing={2}>
                    <Typography>Payment</Typography>
                    <Box border className="stripe-box">
                      <CardElement
                        onChange={status =>
                          setCardEntryComplete(status.complete)
                        }
                        options={{
                          style: {
                            base: {
                              fontSize: isMobile ? '16px' : '22px',
                              color: 'rgba(0, 0, 0, 0.87)',
                              lineHeight: isMobile ? '28px' : '36px',
                              height: isMobile ? 28 : 36,
                              fontFamily: 'Saira, Avenir, Helvetica',
                            },
                          },
                        }}
                      />
                    </Box>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography bold>Print</Typography>
                      <Typography>${product.price.toFixed(2)}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography bold>Shipping</Typography>
                      <Typography>${shipping.toFixed(2)}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography bold>Tax</Typography>
                      <Typography>${tax.toFixed(2)}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography bold>Total</Typography>
                      <Typography>${total.toFixed(2)}</Typography>
                    </Stack>
                    <LoadingButton
                      disabled={!cardEntryComplete}
                      loading={creatingOrder}
                      onClick={handlePurchasePrint}
                      fullWidth
                      variant="contained">
                      {user ? 'PURCHASE' : 'SIGN IN FIRST'}
                    </LoadingButton>
                    <Typography variant="caption">
                      *All print sales are final
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductScreen);
